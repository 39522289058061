#authors-table {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px;
}

.author-groups {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin: 5px;
}

.group-title {
  padding: 10px;
}

.author-groups-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#authors-table a {
  padding: 5px;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.link-div {
  margin: 5px;
}

a {
  color: black;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 15px;
  letter-spacing: 0;
  word-spacing: 0;
  font-weight: 400;
  text-decoration: none solid #444;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
}

#loading-div {
  margin-left: auto;
  margin-right: auto;
}

#authors-table-header {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 35px;
}

.flex-container > span {
  font-size: 20px;
}

.flex-item {
  width: 100px;
  margin: 10px;
  border: 1px solid black;
  font-size: 15px;
  padding: 10px;
  flex: auto;
}

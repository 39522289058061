#navigation a {
  margin-top:1rem
}

#navigation {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#router-div{
  width: 100%;
  min-height: 100vh;
}

#listAll{
  background-color: #de8030
}

#menu{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

}

#love{
  background-color: #e44848;
}

#wisdom{
  background-color: #5ec161;
}

#philosophy{
  background-color: #7b5ec1;
}

#inspiration{
  background-color: #f5f477;
}

#life{
  background-color: #61abff;
}

#success{
  background-color: #d568bb;
}

.menu-items {
  border-radius: 15px;
  margin: 5px;
}
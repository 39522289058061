.search-result {
  border: 2px solid #b6b6bb;
  padding: 5px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
}

.search-result-row {
  display: flex;
  margin: 5px;
}

.search-result-header {
  padding: 20px;
}

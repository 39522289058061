#search-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

#search-search-div {
  width: 100%;
}

#search-button {
  height: 55px;
  width: 120px;
  margin-right: 20px;
}

@media (max-width: 800px) {
  #header-div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  #search-search-div {
    margin-left: 20px !important;
  }
}

#header-div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

#header-logo-link {
  display: flex;
}

#header-logo-themeswitch {
  display: flex;
}

#header-logo a {
  font-size: xxx-large;
}

#header-logo {
  display: flex;
  justify-content: flex-start;
}

#random-quote-div {
  display: flex;
  width: 100%;
  justify-content: space-around;
  justify-content: flex-start;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.footer {
  background-color: #19648d;
  color: white;
  font-size: 20px;
  padding-left: 15px;
}

#quotes > div {
  margin-top: 15px;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

#wiki-link:hover {
  cursor: pointer;
}

#wiki-link {
  background-image: url("/Wikipedia_Logo_Mini.svg");
}

#quotes {
  padding: 30px;
  font-family: Tahoma, Geneva, sans-serif;
  font-size: 21px;
  letter-spacing: 2px;
  word-spacing: 0px;
  clear: both;
}

#author {
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-self: center;
  font-style: italic;
  font-weight: 700;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

#author-header {
  margin-right: 10px;
}

#read-more-style:hover {
  text-decoration: underline;
  color: "white";
}

#read-more-style {
  color: "white";
}
